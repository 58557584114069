<template>
  <div class="view-wrapper align-center overflow-y-auto">
    <v-card class="px-2 ma-2 mt-12 elevation-5" style="width: 350px;">
      <v-card-title class="justify-center">
        {{ $localize('forget_password?') }}
      </v-card-title>
      <v-card-text @keydown.enter="sendEmail">
        <v-text-field v-model="item.email"
                      :rules="[rules.required, rules.email]"
                      :label= "$localize('enter_email')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0 mb-7">
        <v-row class="align-center"
               justify="space-around">
          <v-btn dark
                 color="success"
                 style="display: flex; flex: 0.5;"
                 :outlined="!isFormFilled"
                 @click="sendEmail"
          >
            {{ $localize('confirm') }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data(){
    return{
      item: {
        email: undefined,
        url: location.origin,
      },
      isConfirmButtonDisabled: false,

      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
        email: value => {
          return this.$testRegex('email', value) || this.$localize('enter_email')
        },
      },
    }
  },

  computed:{
    isFormFilled(){
      if (!this.item.email) return false;
      else if (this.isConfirmButtonDisabled) return false;
      else if (this.item.email && !this.$testRegex('email',this.item.email)) return false;
      return true;
    }
  },

  methods:{
    async sendEmail(){
      if(this.isFormFilled){
        this.isConfirmButtonDisabled = true
        this.$appUtils.setOverlay(true)
        const res = await this.$fetch.post('api/auth/send-password-update-link', this.item, true);
        this.$appUtils.setOverlay(false)
        if(res && res.status === 'ACCESS') {
          this.$router.push('/home').catch(() => {})
          this.$store.dispatch('setError', {type: 'success', message: 'letter_was_sent_to_email'})
        }else{
          this.$store.dispatch('setError', {type: 'error', message: 'error_try_again_later'})
          this.isConfirmButtonDisabled = false
        }
      }else this.$store.dispatch('setError', {type: 'warning', message: 'fill_all_fields_correctly'})
    }
  }
}
</script>
